import type { AsyncFunction } from './Types';
import { assert } from './Debug';
import { getEnvBoolean } from './ReactScriptHelper';
import { getCachedValue } from './Cache';
import { Lock } from '@easterngraphics/wcf/modules/utils/async';

import { isUUID } from './string/isUUID';
export { isUUID };

/**
 * Small helper function as replacement for conditional assignment.
 * Note: every call causes an additional function call. However, it
 * most cases it improves the code readability.
 * @deprecated use `??` instead
 */
export function getValue<T>(data: T | undefined | null, fallback: T): T {
    return data ?? fallback;
}

function getWritablePropertyNames(object: object): Array<string> {
    const props: Array<string> = [];

    do {
        const names: Array<string> = Object.getOwnPropertyNames(object);
        for (const name of names) {
            const descriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(object, name);

            if (descriptor == null || descriptor.writable !== true) {
                continue;
            }

            if (props.indexOf(name) === -1) {
                props.push(name);
            }
        }
    } while ((object = Object.getPrototypeOf(object)) != null);

    return props;
}

export function bindMethods<T extends object>(object: T): T {
    for (const key of getWritablePropertyNames(object)) {
        const value: Function | undefined = (object as Record<string, Function>)[key];

        if (
            key !== 'constructor' &&
            typeof value === 'function' &&
            !(object as Record<string, Function>)[key].name.startsWith('bound ')
        ) {
            (object as Record<string, Function>)[key] = value.bind(object);
        }
    }

    return object;
}

export function loadCss(url: string): Promise<void> {
    return new Promise((resolve: VoidFunction, reject: (error: Error) => void): void => {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        link.onload = resolve;
        link.addEventListener('error', (event: ErrorEvent): void => {
            if (event.error != null) {
                reject(event.error);
            } else {
                reject(new Error('Failed to load: ' + url));
            }
        });

        document.head.appendChild(link);
    });
}

export function loadScript(url: string, loadAsync: boolean = true, customPropsSetter?: (script: HTMLScriptElement) => void): Promise<void> {
    return new Promise((resolve: VoidFunction, reject: (error: Error) => void) => {
        const script: HTMLScriptElement = document.createElement('script');

        script.onload = resolve;
        script.addEventListener('error', (event: ErrorEvent): void => {
            if (event.error != null) {
                reject(event.error);
            } else {
                reject(new Error('Failed to load: ' + url));
            }
        });
        script.src = url;
        script.type = 'text/javascript';
        script.async = loadAsync;
        script.defer = loadAsync;

        if (customPropsSetter != null) {
            customPropsSetter(script);
        }

        document.head.appendChild(script);
    });
}

export function injectScript(content: string, type: string = 'text/javascript'): void {
    const script: HTMLScriptElement = document.createElement('script');
    script.type = type;
    script.text = content;

    document.head.appendChild(script);
}

/**
 * x returns random character of [0123456789abcdef]
 *
 * else returns random character of [89abcdef]
 * @param c
 * @returns
 */
function getUUIDReplaceCallback(c: string): string {
    const r: number = Math.random() * 16 | 0;
    const v: number = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
}

const DebugBreakUUIDS: boolean = getEnvBoolean('DEBUG_BREAK_UUIDS', false);
export const getUUID: () => string = DebugBreakUUIDS ? (): string => {
    return 'xxxxxxxx'.replace(/[xy]/g, getUUIDReplaceCallback);
} : (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, getUUIDReplaceCallback);
};

export const UUIDWordExp: RegExp = new RegExp(/\b[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\b/ig);
export function replaceAllUUIDs(value: string, replacer: (uuid: string) => string): string {
    return value.replace(UUIDWordExp, replacer);
}

const returnEmptyString: () => string = () => '';
export const removeUUIDs: (value: string) => string = (value: string) => replaceAllUUIDs(value, returnEmptyString);

export function getRandomHexString(length: number): string {
    let value: string = '';
    for (let i: number = 0; i < length; i++) {
        value += Math.floor(Math.random() * 16).toString(16);
    }

    return value;
}

export const doNothing: VoidFunction = (): void => { /* do nothing */ };

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is Exclude<T, null | undefined> {
    return value != null;
}

export type KeyValuePair = [string, unknown];

export function pairsToDict(entries: Iterable<KeyValuePair>): Record<string, unknown> {
    // Note: `Object.fromEntries` would be nice ;)

    const data: Record<string, unknown> = {};
    for (const [key, value] of entries) {
        if (value != null) {
            data[key] = value;
        }
    }

    return data;
}

export function reduceOrFallback<InputType, ExportType>(
    input: Array<InputType>,
    reducer: (previous: ExportType, current: InputType) => ExportType,
    initialValue: ExportType,
    fallback: ExportType
): ExportType {
    if (!input.length) {
        return fallback;
    }
    return input.reduce(reducer, initialValue);
}

let objectCount: number = 0;
const objectIds: WeakMap<object, number> = new WeakMap<object, number>();
export function getUniqueObjectId(obj: object): number {
    const value: number | undefined = objectIds.get(obj);
    if (value != null) {
        return value;
    }

    objectIds.set(obj, ++objectCount);

    return objectCount;
}

export function wrapForSingleUse(callback: VoidFunction, warn: boolean = true): VoidFunction {
    let called: boolean = false;
    return (): void => {
        if (called === false) {
            called = true;
            callback();
        } else {
            assert(warn !== true, 'this function should only be called once');
        }
    };
}

export function wrapAsyncForSingleUse(callback: AsyncFunction, warn: boolean = true): AsyncFunction {
    let called: boolean = false;
    return async (): Promise<void> => {
        if (called === false) {
            called = true;
            return callback();
        }

        assert(warn !== true, 'this function should only be called once');
    };
}

export function wrapToSkipConcurrentCalls<T extends Array<unknown>>(callback: AsyncFunction<void, T>): AsyncFunction<void, T> {
    let running: boolean = false;
    return async (...agrs: T): Promise<void> => {
        if (!running) {
            running = true;

            try {
                await callback(...agrs);
            } finally {
                running = false;
            }
        }
    };
}

// Note: this function caches the return value. Therefore it should not be used
// for functions where the return value should be garbage collected
export function callOnlyOnce<T>(
    target: object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<() => T>
): TypedPropertyDescriptor<(() => T)> {
    if (descriptor.value != null) {
        let value: T;
        let called: boolean;
        const method: () => T = descriptor.value;
        descriptor.value = function(): T {
            if (called !== true) {
                called = true;
                value = method.apply(this);
            }

            return value;
        };
    }
    return descriptor;
}

export function clearSelection(): void {
    const selection: Selection | null = window.getSelection();
    if (selection?.empty != null) {
        selection.empty();
    }
}

function createNewLock(): Lock {
    return new Lock();
}
export function getFunctionLock<T extends () => unknown>(func: T): Lock {
    return getCachedValue(func, createNewLock);
}