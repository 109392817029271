import { action, computed, observable, makeObservable } from 'mobx';

import { PromiseMethodDecorator } from '@egr/xbox/utils/Promise';
import { withDebugTimer } from '@egr/xbox/utils/Debug';
import { openLoadingDialog, closeLoadingDialog } from '../app-api/AppAction';
import { getEnvBoolean } from '../utils/ReactScriptHelper';
import { ListenableEvent } from '@easterngraphics/wcf/modules/utils';

const NATIVE_PROGRESS: boolean = getEnvBoolean('NATIVE_PROGRESS');

export class ProgressManagerClass {
    private static globalLockCount = 0;
    private static readonly onLockCountChange = new ListenableEvent<-1 | 1, undefined>(undefined);
    static {
        ProgressManagerClass.onLockCountChange.addListener((value) => {
            ProgressManagerClass.globalLockCount += value;
        });

        (window as {_getGlobalLock?: () => Promise<void>})._getGlobalLock = async () => {
            if (ProgressManagerClass.globalLockCount === 0) {
                return Promise.resolve();
            }

            return new Promise((resolve: (data: void) => void) => {
                const eventHandler = () => {
                    if (ProgressManagerClass.globalLockCount === 0) {
                        ProgressManagerClass.onLockCountChange.removeListener(eventHandler);
                        resolve();
                    }
                };

                ProgressManagerClass.onLockCountChange.addListener(eventHandler);
            });
        };
    }

    @computed
    public get inProgress(): boolean {
        return this.lockCount !== 0;
    }

    @observable
    protected lockCount: number = 0;

    constructor(protected progressDelay: number = 0) {
        makeObservable(this);
        this.startProgress = this.startProgress.bind(this);
        this.finishProgress = this.finishProgress.bind(this);
    }

    @action
    public startProgress(): void {
        this.lockCount++;
        ProgressManagerClass.onLockCountChange.trigger(1);

        if (NATIVE_PROGRESS) {
            openLoadingDialog();
        }
    }

    @action
    public finishProgress(): void {
        if (this.lockCount > 0) {
            this.lockCount--;
            ProgressManagerClass.onLockCountChange.trigger(-1);
        }
        if (NATIVE_PROGRESS) {
            closeLoadingDialog();
        }
    }

    public withProgress = async <T>(debugLabel: string, promise: Promise<T>): Promise<T> => {
        let delayedProgressTimeout: number | undefined;
        if (this.progressDelay > 0) {
            delayedProgressTimeout = window.setTimeout(
                () => {
                    delayedProgressTimeout = undefined;
                    this.startProgress();
                },
                this.progressDelay
            );
        } else {
            this.startProgress();
        }

        try {
            return await withDebugTimer(debugLabel, (): Promise<T> => {
                return promise;
            });
        } finally {
            if (delayedProgressTimeout != null) {
                clearTimeout(delayedProgressTimeout);
            } else {
                this.finishProgress();
            }
        }
    };

    public withInstantProgress = async <T>(debugLabel: string, promise: () => Promise<T>, ignoreProgress: boolean = false): Promise<T> => {
        let delayedProgressTimeout: number | undefined;
        if (ignoreProgress !== true) {
            if (this.progressDelay > 0) {
                delayedProgressTimeout = window.setTimeout(
                    () => {
                        delayedProgressTimeout = undefined;
                        this.startProgress();
                    },
                    this.progressDelay
                );
            } else {
                this.startProgress();
            }
        }

        try {
            return await withDebugTimer(debugLabel, (): Promise<T> => {
                return promise();
            });
        } finally {
            if (ignoreProgress !== true) {
                if (delayedProgressTimeout != null) {
                    clearTimeout(delayedProgressTimeout);
                } else {
                    this.finishProgress();
                }
            }
        }
    };
}

export const ProgressManager: ProgressManagerClass = new ProgressManagerClass();
export const HideProgressManager: ProgressManagerClass = new ProgressManagerClass();

export function wrapWithProgress<Args extends Array<unknown>, ReturnType>(
    label: string,
    callback: (...args: Args) => Promise<ReturnType>,
    customManager: ProgressManagerClass = ProgressManager
): (...args: Args) => Promise<ReturnType> {
    return (...args: Args): Promise<ReturnType> => {
        return customManager.withInstantProgress<ReturnType>(
            label,
            (): Promise<ReturnType> => {
                return callback(...args);
            }
        );
    };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function withProgress<T>(
    label: string,
    customManager: ProgressManagerClass = ProgressManager,
    ignoreProgress?: (self: unknown) => boolean
): PromiseMethodDecorator {
    return (
        target: object,
        propertyKey: string | symbol,
        descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>>
    ) => {
        const method: ((...args: Array<any>) => Promise<T>) | undefined = descriptor.value;
        if (method !== undefined) {
            descriptor.value = function(...args: Array<any>): Promise<T> {
                return customManager.withInstantProgress(
                    label,
                    (): Promise<T> => {
                        return method.apply(this, args);
                    },
                    ignoreProgress?.(this)
                );
            };
        }
        return descriptor;
    };
}
/* eslint-enable @typescript-eslint/no-explicit-any */