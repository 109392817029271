import { sendMessage, Message, MessageTimeout, registerAppError, AppError, getAppErrorMessage, BaseMessage } from './Communication';
import { appInfo } from './AppInfo';

import * as Sentry from '@sentry/browser';
import { AppTheme, layoutInformation } from '@egr/xbox/egr-gui-elements/Helper/Darkmode';
import { CatalogViewType } from '@egr/xbox/catalog-view/Managers/CatalogViewManager';
import { CookieState} from '@egr/xbox/base-app/managers/CookieManager';
import { CropMargins } from '@egr/xbox/utils/Canvas';
import { ErrorInformation } from '@egr/xbox/utils/Mail';
import { ImageSize } from '@egr/xbox/utils/Image';
import { Omit } from '@egr/xbox/utils/Types';
import { getUUID, wrapForSingleUse } from '@egr/xbox/utils/Helper';

export let autoRotate: boolean = true;

export interface AppAction<T> extends Message<T> {
    type: 'appaction';
}

type AppActionWithoutArguments = Omit<AppAction<undefined>, 'arguments'>;

interface BackgroundContentSynchronizationArgument {
    url: string;
    path: string;
}

export function backgroundContentSynchronization(url: string, path: string): Promise<void> {
    return sendMessage<AppAction<BackgroundContentSynchronizationArgument>>({
        type: 'appaction',
        action: 'background_content_synchronization',
        arguments: {
            url: url,
            path: path
        }
    });
}

function doNothing(): void {
    // Used to ignore exceptions
}

// Note: right now we have to cancel the background content synchronization via
// an invalid request
export const cancelBackgroundContentSynchronization: () => Promise<void> = (): Promise<void> => backgroundContentSynchronization('', '').catch(doNothing);

type StatusbarVisibilities = 'show' | 'hide';

function setStatusbarVisibility(visibility: StatusbarVisibilities): void {
    return sendMessage<AppAction<StatusbarVisibilities>>(
        {
            type: 'appaction',
            action: 'statusbar',
            arguments: visibility
        },
        true
    );
}

export const showStatusbar: VoidFunction = (): void => setStatusbarVisibility('show');
export const hideStatusbar: VoidFunction = (): void => setStatusbarVisibility('hide');

type StatusbarStyles = 'dark' | 'light';

/**
 * This message can be used to change the status bar style.
 */
function statusbarRequest(mode: StatusbarStyles): void {
    return sendMessage<AppAction<StatusbarStyles>>(
        {
            type: 'appaction',
            action: 'statusbar_style',
            arguments: mode
        },
        true
    );
}

export const setLightStatusbar: VoidFunction = (): void => statusbarRequest('light');
export const setDarkStatusbar: VoidFunction = (): void => statusbarRequest('dark');

type FullscreenAction = 'enter' | 'leave';

function fullscreenRequest(mode: 'enter' | 'leave'): void {
    return sendMessage<AppAction<FullscreenAction>>(
        {
            type: 'appaction',
            action: 'fullscreen',
            arguments: mode
        },
        true
    );
}

export const enterFullscreen: VoidFunction = (): void => fullscreenRequest('enter');
export const leaveFullscreen: VoidFunction = (): void => fullscreenRequest('leave');

interface RequestProgress {
    time: number;
    request_id: number;
}

interface DownloadProgress {
    size: number;
    fetched: number;
}

export interface DownloadRequestProgress extends RequestProgress {
    downloads: Array<DownloadProgress>;
}

@registerAppError('f030fe5b-4b3d-4a93-90f9-b24ba382882a')
export class DownloadCanceled extends AppError {
    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, DownloadCanceled.prototype);
    }
}

interface CancelDownloadActionArgument {
    request_id: number;
    error_message: string;
}

export function cancelDownload(requestId: number): void {
    return sendMessage<AppAction<CancelDownloadActionArgument>>(
        {
            type: 'appaction',
            action: 'cancel_download',
            arguments: {
                request_id: requestId,
                error_message: getAppErrorMessage(DownloadCanceled, 'Download canceled')
            }
        },
        true
    );
}

export interface PreviewFile {
    url: string;
    mimetype: string;
    title: string;
}

export function downloadPercentageReducer(acc: number | null, download: DownloadProgress): number {
    // Note: -1 is send if the size is unknown
    if (download.size < 1 || isNaN(acc!)) {
        return NaN;
    }

    const percentage: number = download.fetched / download.size;

    if (acc == null) {
        return percentage;
    }

    return (acc + percentage) / 2;
}

export interface PreviewRequest {
    promise: Promise<void>;
    cancel: () => void;
}

// Note: the `percentage` value of the `progressCallback` can be `NaN`
export function showPreview(arg: PreviewFile, progressCallback?: (percentage: number) => void): PreviewRequest {
    let transactionId: number;
    const promise: Promise<void> = sendMessage<AppAction<PreviewFile>, void, DownloadRequestProgress>(
        {
            type: 'appaction',
            action: 'preview',
            arguments: arg
        },
        false,
        MessageTimeout,
        {
            transactionId: (value: number): void => {
                transactionId = value;
            },
            progress: progressCallback == null ? undefined : (data: DownloadRequestProgress): void => {
                progressCallback(data.downloads.reduce(downloadPercentageReducer, null)! * 100);
            }
        }
    );

    return {
        promise,
        cancel: wrapForSingleUse((): void => {
            return cancelDownload(transactionId);
        })
    };
}

export function openBrowser(url: string): void {
    return sendMessage<AppAction<string>>(
        {
            type: 'appaction',
            action: 'openBrowser',
            arguments: url
        },
        true
    );
}

export function mergeImages(inputs: Array<string>, output: string, quality: number = 90): Promise<void> {
    return sendMessage<AppAction<{ paths: Array<string>, target: string, quality: number }>>({
        type: 'appaction',
        action: 'merge_images',
        arguments: {
            paths: inputs,
            target: output,
            quality
        }
    });
}

export interface CropImageParameters {
    source: string;
    destination: string;
    margins: CropMargins;
    quality?: number;
    reference_size?: ImageSize;
}

export function minimize(): void {
    sendMessage<AppActionWithoutArguments>(
        {
            type: 'appaction',
            action: 'minimize'
        },
        true
    );
}

/**
 * This message can be used to change the status bar color.
 * @param color hex-code
 */
export function setStatusbarColor(color: string): void {
    // app-api expects hex-code without hash
    if (color.startsWith('#')) {
        color = color.substr(1);
    }

    // app-Api accepts only 6 digit hex-code
    if (color.length === 3) {
        color = `${color[0]}${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}`;
    }

    return sendMessage<AppAction<string>>(
        {
            type: 'appaction',
            action: 'statusbar_color',
            arguments: color,
        },
        true
    );
}

/**
 * This message can be used for enable/disable the autorotation.
 * Note: the app should start with the default autorotate state.
 * @param value
 */
export function enableAutorotate(value: boolean): void {
    autoRotate = value;
    return sendMessage<AppAction<'enable' | 'disable'>>(
        {
            type: 'appaction',
            action: 'autorotate',
            arguments: (value ? 'enable' : 'disable')
        },
        true
    );
}

export type OrientationMode = 'portrait' | 'landscape' | 'all';

/**
 * This message can be used for change the orientation mode.
 * Note: the app should start with the default orientation mode.
 * Allowed values are:
 *  portrait: portrait orientation mode
 *  landscape: landscape orientation mode
 *  all: portrait and landscape orientations mode
 * @param value
 */
export function setOrientationMode(value: OrientationMode): void {
    return sendMessage<AppAction<OrientationMode>>(
        {
            type: 'appaction',
            action: 'orientation_mode',
            arguments: value
        },
        true
    );
}

export type VrModeAction = 'enter' | 'leave' | 'pause' | 'resume';

function arModeRequest(mode: VrModeAction): void {
    return sendMessage<AppAction<VrModeAction>>(
        {
            type: 'appaction',
            action: 'ar',
            arguments: mode
        },
        true
    );
}

export const enterArMode: VoidFunction = (): void => arModeRequest('enter');
export const leaveArMode: VoidFunction = (): void => arModeRequest('leave');
export const pauseArMode: VoidFunction = (): void => arModeRequest('pause');
export const resumeArMode: VoidFunction = (): void => arModeRequest('resume');

interface ArSettings {
    include_camera_frame: boolean;
    show_feature_points: boolean;
    dump_camera_frame?: string;
}

// Note: the frame is send within the date of the `ar_update` web action
export function requestArCameraFrame(): void {
    return sendMessage<AppAction<Partial<ArSettings>>>(
        {
            type: 'appaction',
            action: 'ar_settings',
            arguments: {
                include_camera_frame: true,
                dump_camera_frame: `/tmp/ar_${getUUID()}.png`
            }
        },
        true
    );
}

export function showArFeaturePoints(value: boolean): void {
    return sendMessage<AppAction<Partial<ArSettings>>>(
        {
            type: 'appaction',
            action: 'ar_settings',
            arguments: {
                show_feature_points: value
            }
        },
        true
    );
}

type ImportFileTypes =
    'image/png' |
    'image/jpeg' |
    'com.easterngraphics.pbox' |
    'com.easterngraphics.opl' |
    'com.easterngraphics.pec' |
    'com.easterngraphics.obk' |
    'com.easterngraphics.obx' |
    'com.easterngraphics.eox';

export interface ImportFileArg {
    file_types: Array<ImportFileTypes>;
    source?: 'camera' | 'gallery';
    context?: string;
}

export function importFile(importFileArg: ImportFileArg): void {
    return sendMessage<AppAction<ImportFileArg>>(
        {
            type: 'appaction',
            action: 'import',
            arguments: importFileArg
        },
        true
    );
}

export interface MailAttachment {
    path: string;
    name: string;
    mimeType: string;
}

export interface MailOptions {
    recipients?: string;
    subject: string;
    body: string;
    html: boolean;
    attachments?: Array<MailAttachment>;
}

export function composeMail(options: MailOptions): void {
    return sendMessage<AppAction<MailOptions>>(
        {
            type: 'appaction',
            action: 'compose_mail',
            arguments: options
        },
        true
    );
}

export interface FileInformation {
    path: string;
    name: string;
}

export function clearCache(): Promise<void> {
    return sendMessage<BaseMessage>(
        {
            type: 'appaction',
            action: 'clear_cache'
        },
        false
    );
}

export type ShutterFlash = 'white-out' | 'white-out-flash' | 'black-out';

export interface ShutterFlashArguments {
    mode: ShutterFlash;
}

export function shutterFlash(flash: ShutterFlash = 'white-out'): void {
    return sendMessage<AppAction<ShutterFlashArguments>>(
        {
            type: 'appaction',
            action: 'shutter_flash',
            arguments: {
                mode: flash
            }
        },
        true
    );
}

export interface VideoPlaybackArgs {
    video_id: string;
    context?: string;
}

export async function startVideoPlayback(videoPlaybackArgs: VideoPlaybackArgs): Promise<void> {
    return sendMessage<AppAction<VideoPlaybackArgs>>(
        {
            type: 'appaction',
            action: 'play_video',
            arguments: videoPlaybackArgs
        },
        true
    );
}

export async function dismissVideoPlayback(): Promise<void> {
    return sendMessage<BaseMessage>(
        {
            type: 'appaction',
            action: 'dismiss_video'
        },
        true
    );
}

export interface ArPreviewArguments {
    url: string;
}

/**
 * @param url download url / path to local file
 */
export async function arPreview(url: string): Promise<void> {
    return sendMessage<AppAction<ArPreviewArguments>>(
        {
            type: 'appaction',
            action: 'ar_preview',
            arguments: { url },
        },
        true
    );
}

export interface AppThemeArguments {
    theme: AppTheme;
}

/**
 * Allows to change the native theme reported and used by the application.
 * Note:
 * - if unsed the theme mode defaults to 'auto'
 * - if set to 'light' or 'dark' this setting persists between application restarts
 */
export async function setAppTheme(theme: AppTheme): Promise<void> {
    return sendMessage<AppAction<AppThemeArguments>>(
        {
            type: 'appaction',
            action: 'set_app_theme',
            arguments: { theme }
        },
        true
    );
}

export function registerAppThemeChange(): void {
    if (appInfo?.supports_themes) {
        layoutInformation.layoutModeObservable.observe_(
            (value) => {
                void setAppTheme(value.newValue as AppTheme);
            }
        );
    }
}

export type HapticFeedback = 'success' | 'warning' | 'error' | 'selection_changed' | 'buzz';

export interface HapticFeedbackArguments {
    type: HapticFeedback;
}

/**
 * Can be used to give haptic feedback to user
 * Note:
 * - if the target device does not support certain notification a fallback will be played
 * - if the target device does not support haptic feedback no action will be performed
 */
export async function hapticFeedback(feedback: HapticFeedback): Promise<void> {
    return sendMessage<AppAction<HapticFeedbackArguments>>(
        {
            type: 'appaction',
            action: 'haptic_feedback',
            arguments: {
                type: feedback
            }
        },
        true
    );
}

export function showNativeDebug(): void {
    sendMessage<AppActionWithoutArguments>(
        {
            type: 'appaction',
            action: 'show_native_debug'
        },
        true
    );
}

export function setBottomIndex(index: number): void {
    return sendMessage<AppAction<number>>(
        {
            type: 'appaction',
            action: 'set_navigation_index',
            arguments: index,
        },
        true
    );
}

export function setAppLayer(layer: string): void {
    return sendMessage<AppAction<string>>(
        {
            type: 'appaction',
            action: 'set_native_layer',
            arguments: layer
        },
        true
    );
}

export function setGUILanguage(language: string): void {
    sendMessage<AppAction<string>>(
        {
            type: 'appaction',
            action: 'set_gui_language',
            arguments: language
        },
        true
    );
}

export function setHideUsedCatalogs(hide: boolean): void {
    sendMessage<AppAction<boolean>>(
        {
            type: 'appaction',
            action: 'set_hide_used_catalogs',
            arguments: hide
        },
        true
    );
}

export interface FeedbackInfo {
    email: string;
    errorInfo: ErrorInformation;
    appUrl: string;
    sentryEvents?: Array<Sentry.Event>;
}

export interface ErrorArguments {
    title: string;
    subTitle: string;
    feedbackInfo?: FeedbackInfo;
    closeLabel?: string;
    error?: string;
    details?: string;
}

export function triggerErrorDialogAction(args: ErrorArguments): void {
    sendMessage<AppAction<ErrorArguments>>(
        {
            type: 'appaction',
            action: 'error',
            arguments: args,
        },
        true
    );
}

export function openLoadingDialog(): void {
    sendMessage<AppActionWithoutArguments>(
        {
            type: 'appaction',
            action: 'startProgress',
        },
        true
    );
}

export function closeLoadingDialog(): void {
    sendMessage<AppActionWithoutArguments>(
        {
            type: 'appaction',
            action: 'finishProgress',
        },
        true
    );
}

export function setCatalogViewType(args: CatalogViewType): void {
    sendMessage<AppAction<CatalogViewType>>(
        {
            type: 'appaction',
            action: 'catalog_view_type',
            arguments: args,
        },
        true
    );
}

export function pulseBasketIcon(): void {
    sendMessage<AppActionWithoutArguments>(
        {
            type: 'appaction',
            action: 'pulse_basket_icon',
        },
        true
    );
}

export function setUnreadNews(value: boolean): void {
    sendMessage<AppAction<boolean>>(
        {
            type: 'appaction',
            action: 'set_unread_news',
            arguments: value,
        },
        true
    );
}

export function nativeLog(action: 'log'| 'debug' | 'warn' | 'error', args: string): void {
    sendMessage<Message<string>>(
        {
            type: 'log',
            action: action,
            arguments: args,
        },
        true
    );
}

export function getCookieState(): Promise<CookieState> {
    return sendMessage<AppActionWithoutArguments, CookieState>(
        {
            type: 'appaction',
            action: 'get_cookie_state',
        },
        false,
    );
}

export function openNativeYoutubeDialog(id: string): void {
    sendMessage<Message<string>>(
        {
            type: 'appaction',
            action: 'open_youtube_dialog',
            arguments: id,
        },
        true
    );
}

export function saveWebCookieState(state: CookieState): void {
    sendMessage<AppAction<CookieState>>(
	{
            type: 'appaction',
            action: 'set_web_cookie_state',
            arguments: state,
	},
	true,
    );
}
