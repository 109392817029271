import type { Transport, Envelope } from '@sentry/types';
import { BrowserTransportOptions } from '@sentry/browser/types/transports/types';
import { makeXHRTransport } from '@sentry/browser';

import { PromiseCallbacks, getPromiseCallbacks } from '../promise/Callbacks';
import { getLastException } from '../Error';

export const sentryTransportLog = new WeakMap<Error, Promise<boolean>>();

// Note: some click handlers require a synchronous way to read the state of the transmission
export const sentrySyncTransportLog = new WeakMap<Error, boolean>();

export function TransportWithLog(options: BrowserTransportOptions): Transport {
    const {send, ...transport} = makeXHRTransport(options);

    async function sendOverride(request: Envelope) {
        const lastError: Error | null = getLastException();
        const promiseCallbacks: PromiseCallbacks<boolean> = getPromiseCallbacks<boolean>();

        if (lastError != null) {
            sentryTransportLog.set(lastError, promiseCallbacks.promise);
            try {
                const response = await send(request);
                promiseCallbacks.resolve(true);
                sentrySyncTransportLog.set(lastError, true);
                return response;
            } catch (error) {
                promiseCallbacks.resolve(false);
                throw error;
            }
        } else {
            return send(request);
        }
    }

    //eslint-disable-next-line @typescript-eslint/dot-notation
    transport['send'] = sendOverride.bind(transport);

    return transport as Transport;
}