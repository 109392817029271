import * as React from 'react';
import * as Sentry from '@sentry/browser';
import merge from 'lodash/merge';
import { CaptureContext, SeverityLevel, ScopeContext, Primitive } from '@sentry/types';
import { RewriteFrames } from '@sentry/integrations';

import VersionInfo, { isReleaseVersion } from './Version';
import { EAIWS_SERVER } from './Constants';
import { Logger } from './errors/Logger';
import { assert } from './Debug';
import { developmentMode, getEnvArray, getEnvBoolean, getEnvVar } from './ReactScriptHelper';
import { fixFrame } from './errors/sentry/FixAppFramesIntegration';
import { parseQueryString } from './Url';
import { removeUUIDs } from './Helper';
import { removeUndefinedValues } from './Object';
import { sentryTransportLog, TransportWithLog } from './errors/Transport';

import { appInfo } from '@egr/xbox/app-api/AppInfo';

import { AjaxRequestInfo } from '@easterngraphics/wcf/modules/utils/async';
import { CustomError } from '@easterngraphics/wcf/modules/utils/error';
import { SoapError } from '@easterngraphics/wcf/modules/eaiws';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { wcfConfig } from '@easterngraphics/wcf/modules/utils';

export function enableSentry(sentryEnabled: boolean): void {
    preventSend = !sentryEnabled;
}

let preventSend: boolean = true;

export class WrappedError extends CustomError {
    public constructor(error: Error, errorMessage?: string) {
        super(error.name);

        Object.setPrototypeOf(this, WrappedError.prototype);

        this.message = errorMessage ?? error.message;
        this.stack = error.stack;

    }
}

/**
 * enum for Sentry.SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
 * (enum Sentry.Severity is deprecated)
 */
export const enum ErrorLevel {
    Fatal = 'fatal',
    Error = 'error',
    Warning = 'warning',
    Log = 'log',
    Info = 'info',
    Debug = 'debug'
}
export type ErrorOptions = CaptureContext;
export type ErrorBreadcrumb = Sentry.Breadcrumb;

export const errorTrackingEnabled: () => boolean = () => Sentry.getCurrentHub().getClient() != null;
let lastEventId: string | undefined = undefined;
export const getLastErrorId: () => string | undefined = () => lastEventId;

export class ErrorWithRavenOptions extends Error {
    public constructor(message?: string, public ravenOptions?: ErrorOptions) {
        super(message);

        Object.setPrototypeOf(this, ErrorWithRavenOptions.prototype);
    }
}

if (developmentMode) {
    (window as { _Sentry?: unknown })._Sentry = Sentry;
    (window as { _captureException?: unknown })._captureException = (message?: string, level: 'fatal' | 'error' | 'debug' = 'debug') => {
        captureException(new Error(message), level);
    };
}

const tags: ScopeContext['tags'] = {
    url: appInfo != null ? appInfo.initialization_url : window.location.href,
};

if (window.location.hash) {
    tags.hash = window.location.hash;
}

export function updateTag(key: string, value: Primitive): void {
    tags[key] = value;
}

export function getTags(): ScopeContext['tags'] {
    return tags;
}

/**
 * The following level should be uses:
 *
 * - `fatal` if the error can not be handled an the project must be closed
 * - `error` if the error can be handled and a error message/dialog is shown
 * - `debug` if the error can be handled without the user noticing
 */
export function captureException(error: Error, errorLevel: 'fatal' | 'error' | 'debug', options?: ErrorOptions): void {
    if (errorLevel === 'debug') {
        console.warn(error);
    }
    if (sentryHasIgnoreError(error) || (isNotNullOrEmpty(EAIWS_SERVER) && !EAIWS_SERVER.includes('pcon-solutions.com'))) {
        return;
    }

    const level: SeverityLevel = fromString(errorLevel);

    const errorOptions: ErrorOptions = merge<
        ErrorOptions,
        ErrorOptions,
        ErrorOptions,
        ErrorOptions
    >(
        {
            level,
            tags,
            contexts: {
                hash: parseQueryString(window.location.hash.slice(1))
            }
        },
        error instanceof SoapError ? {
            fingerprint: [removeUUIDs(error.message).trim(), 'SoapError', error.methodName ?? ''], // soap errors should only be grouped by the error message
            tags: isNotNullOrEmpty(error.methodName) ? {
                soapMethod: error.methodName,
                eaiwsServer: (window as { _App?: import('@egr/xbox/base-app/App').App })._App?.eaiwsSession?.baseUrl,
            } : undefined
        } : {},
        options ?? {},
        (error as ErrorWithRavenOptions).ravenOptions ?? {}
    );

    if (errorTrackingEnabled()) {
        // eslint-disable-next-line no-restricted-properties
        Sentry.captureException(error, errorOptions);
    } else {
        Logger.error(error, errorOptions);
    }
}

function fromString(level: SeverityLevel): SeverityLevel {
    switch (level) {
        case 'fatal':
        case 'error':
        case 'warning':
        case 'info':
        case 'debug':
            return level;
        case 'log':
        default:
            return 'log';
    }
}

function getErrorName(error: Error): string {
    // Note: since the js minifier change the code, the error name is mostly useless.
    // This is an attempt to get a useful name based on the error object.

    if (error.constructor === SoapError) {
        return 'SoapError';
    }

    if (error.name === 'DataError') {
        return 'DataError';
    }

    return error.constructor.name;
}

export function getSentryTransportState(error: Error): Promise<boolean> {
    const promise: Promise<boolean> | undefined = sentryTransportLog.get(error);
    if (promise == null) {
        return Promise.resolve(false);
    }

    return promise;
}

function setupImprovedSoapBreadcrumbs(): void {
    if (
        assert(wcfConfig.ajaxResolvedEvent == null, 'ajaxResolvedEvent must be undefined') &&
        assert(wcfConfig.ajaxRejectedEvent == null, 'ajaxRejectedEvent must be undefined')
    ) {
        let methodNameStartPosition: number | null = null;
        const getMethodName: (body: string) => string = (body: string): string => {
            if (methodNameStartPosition == null) {
                // Note: is kind of a ugly hack. A regex could be a better solutions
                // but could also have a bigger impact on the performance. A change to
                // the core would properly be the best solution.

                methodNameStartPosition = body.indexOf(
                    '<', // first opening tag
                    body.indexOf('s:Body') // after the `s:Body` tag
                ) + 1;
            }

            // return string from the beginning of the `methodNameStartPosition` to the
            // first space
            return body.substring(
                methodNameStartPosition,
                body.indexOf(' ', methodNameStartPosition)
            );
        };

        const sessionRegExp = new RegExp('<sessionId[^>]+>(?<id>[a-z0-9-]{36})</');
        const getSessionId = (body: string): string | undefined => {
            return sessionRegExp.exec(body)?.groups?.id;
        };

        const itemRegExp = new RegExp('<itemId[^>]+>(?<id>[a-z0-9-]{36})</');
        const getItemId = (body: string): string | undefined => {
            return itemRegExp.exec(body)?.groups?.id;
        };

        const callback: typeof wcfConfig.ajaxResolvedEvent = (requestInfo: AjaxRequestInfo, xhrRequest: XMLHttpRequest, error?: Error): void => {
            try {
                if (requestInfo.method === 'POST' && requestInfo.url.includes('/EAI/') && requestInfo.options?.dataType === 'xml') {
                    Sentry.addBreadcrumb({
                        type: 'http',
                        category: 'xhr',
                        data: {
                            url: requestInfo.url,
                            method: requestInfo.method,
                            status_code: xhrRequest.status,
                            soapMethod: getMethodName(requestInfo.data as string),
                            sessionId: getSessionId(requestInfo.data as string),
                            itemId: getItemId(requestInfo.data as string),
                            error: error?.message
                        }
                    });
                }
            } catch (e) {
                Logger.error(e);
            }
        };
        wcfConfig.ajaxResolvedEvent = callback;
        wcfConfig.ajaxRejectedEvent = callback;
    }
}

let lastSentryError: Error | null = null;
Sentry.addGlobalEventProcessor((event: Sentry.Event, hint?: Sentry.EventHint) => {
    if (hint?.originalException instanceof Error) {
        lastSentryError = hint.originalException;
    }
    return event;
});

export function getLastException(): Error | null {
    return lastSentryError;
}

const sentryIgnoreList: WeakSet<Error> = new WeakSet<Error>();
const sentryUrl: string = getEnvVar('SENTRY_URL', '');
const eaiwsServer: string = getEnvVar('EAIWS_SERVER', '');

export const ErrorStack = new class ErrorStack {
    private _errors: Array<Sentry.Event> = [];

    public get errors(): Array<Sentry.Event> {
        return this._errors;
    }

    public constructor(private limit: number = 1) { }

    public push(error: Sentry.Event): void {
        this._errors = [error, ...this._errors].slice(0, this.limit);
    }

    public toString(): string {
        return JSON.stringify(this.errors, null, 2);
    }

}();

export function setupSentry(): void {
    if (isNotNullOrEmpty(sentryUrl) || isNotNullOrEmpty(sentryUrl) && eaiwsServer.includes('.eaiws.pcon-solutions.com')) {
        const breadcrumbFilter = getEnvArray('SENTRY_FILTER_BREADCRUMBS');

        if (breadcrumbFilter.length === 0 || breadcrumbFilter.includes('soap') || breadcrumbFilter.includes('xhr')) {
            setupImprovedSoapBreadcrumbs();
        }

        const sentryOptions: Sentry.BrowserOptions = {
            // source: https://docs.sentry.io/clients/javascript/tips/
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'Can\'t find variable: ZiteReader',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                // Generic error code from errors outside the security sandbox
                // You can delete this if using raven.js > 1.0, which ignores these automatically.
                'Script error.',
                // Avast extension error
                '_avast_submit'
            ],
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
            ],
            dsn: sentryUrl,
            sendClientReports: false,
            normalizeDepth: 5,
            autoSessionTracking: false,
            environment: isReleaseVersion ? 'production' : 'development',
            beforeSend: (data: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event | PromiseLike<Sentry.Event | null> | null => {
                lastEventId = data.event_id;
                ErrorStack.push(data);

                try {
                    const lastError: Error | null = getLastException();
                    const currentError: Sentry.Exception | undefined = data.exception?.values?.[0];

                    if (currentError != null) {
                        if (
                            lastError != null &&
                            // should not be necessary, but I want to be on the safe side
                            currentError.type === lastError.name &&
                            currentError.value === lastError.message
                        ) {
                            currentError.type = getErrorName(lastError);
                        }
                    }
                } catch (error) {
                    // ignore
                }

                if (preventSend) {
                    return null;
                }

                return data;
            },
            transport: TransportWithLog,
            beforeBreadcrumb: (data: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint | undefined): Sentry.Breadcrumb | null => {
                if (breadcrumbFilter.length > 0 && !breadcrumbFilter.includes(data.category ?? '')) {
                    return null;
                }

                if (
                    data.category === 'xhr' &&
                    data?.data?.method === 'POST' &&
                    data?.data?.url?.includes('/EAI') &&
                    data?.data?.soapMethod == null
                ) {
                    // filter out the original soap request -> request will be logged via custom logger (soap) and
                    // therefore the breadcrumb entry duplicated
                    return null;
                }
                if (developmentMode && data.category === 'console') {
                    return null;
                }

                if (data?.data !== undefined) {
                    data.data = removeUndefinedValues(data.data);
                }
                if (data?.category === 'ui.click') {
                    const sentryLabel: RegExpExecArray | null = /\[data-sentrylabel=([^\]]*)\]/i.exec(data.message ?? '');
                    if (isNotNullOrEmpty(sentryLabel?.[1])) {
                        data.message = sentryLabel?.[1];
                    }
                    else if (hint?.event != null) {
                        const { target } = hint.event;
                        if (target instanceof HTMLElement) {
                            data.message = getSentryLabel(target) ?? data.message;
                        }
                    }
                }
                return data;
            },
            integrations: [
                new RewriteFrames({
                    prefix: 'pcon://',
                    iteratee: fixFrame
                }),
                new Sentry.Integrations.GlobalHandlers({
                    onerror: true,
                    onunhandledrejection: false
                }),
                new Sentry.Integrations.Breadcrumbs({
                    console: true,
                    dom: { serializeAttribute: 'data-sentrylabel' },
                    fetch: true,
                    history: true,
                    sentry: true,
                    xhr: true,
                })]
        };

        if (VersionInfo != null) {
            let release: string = VersionInfo.version;
            if (isNotNullOrEmpty(VersionInfo.commit)) {
                release += '-' + VersionInfo.commit;
            }
            sentryOptions.release = release;
        }

        Sentry.init(sentryOptions);
    }
}

function getSentryLabel(target: HTMLElement | null) {
    if (target == null) {
        return null;
    }
    return target.getAttribute('data-sentrylabel') ?? getSentryLabel(target.parentElement);
}

export function getErrorContext(key: string): undefined | Record<string, string> {
    /*
    const context: {extra?: Record<string, Record<string, string> | undefined>} = Raven.getContext();
    return context?.extra?.[key];
    */
    return undefined;
}

export function setErrorContext(category: string, data: unknown): void {
    Sentry.setExtra(category, data);
}

const LOG_BREADCRUMB = getEnvBoolean('LOG_BREADCRUMB', developmentMode);

export function addBreadcrumb(breadcrumb: ErrorBreadcrumb): void {
    try {
        if (LOG_BREADCRUMB) {
            const { category, level, message, data } = breadcrumb;
            // Note: it's only debug code -> so ignore the eslint issue
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const args: Array<unknown> = [`[${category}] ${message}`, data].filter(Boolean);
            switch (level) {
                case 'error':
                case 'fatal':
                    Logger.error(...args);
                    break;
                case 'warning':
                    Logger.warn(...args);
                    break;
                default:
                    Logger.log(...args);
                    break;
            }
        }

        Sentry.addBreadcrumb(breadcrumb);
    } catch (error) {
        Logger.error(error);
    }
}

export function sentryShouldIgnoreError(error: Error): void {
    sentryIgnoreList.add(error);
}

export function sentryHasIgnoreError(error: Error): boolean {
    return sentryIgnoreList.has(error);
}

export const SentryLabelContext: React.Context<string | undefined | null> = React.createContext<string | undefined | null>(null);
