import * as React from 'react';

import PlaceholderImage from '../../../layout/res/logo_with_text.svg';

import { makeStyles, MakeStylesHook } from '@egr/xbox/utils/tss-react';

const useBoxSplashscreenImageStyles: MakeStylesHook<'root'> = makeStyles({ name: 'BoxSplashscreenImage'})({
    root: {
        width: 177,
        '& > img': {
            margin: 'auto',
            maxWidth: '100%',
        }
    }
});

export const BoxSplashscreenImage: React.ComponentType = () => {
    const { classes } = useBoxSplashscreenImageStyles();

    return (
        <div className={classes.root}>
            <img src={PlaceholderImage}/>
        </div>
    );
};