import { Css, Cx, CSSObject } from 'tss-react/types';
import { Theme, useTheme } from '@mui/material/styles';
import { createMakeAndWithStyles, keyframes } from 'tss-react';
import createCache from '@emotion/cache';

const tssCache = createCache({
    key: 'tss-custom'
});

tssCache.compat = true; // disabled SSR-Warnings

export const { makeStyles, withStyles } = createMakeAndWithStyles({
    useTheme,
    cache: tssCache
});

/** short callback to produce string like here: https://docs.tss-react.dev/page-1/keyframes */
export function getKeyframes(
    code: string,
    additionalParameters: string = '',
): string {
    return `${keyframes`${code}`} ${additionalParameters}`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type StyleRules<Unused = {}, ClassNames extends string = string> = Record<ClassNames, CSSObject>;

export interface WithStyles<ClassNames extends string = never> { classes?: Record<ClassNames, string> }

export interface MakeStylesResult<ClassNames extends string = string> {
    classes: Record<ClassNames, string>;
    theme: Theme;
    css: Css;
    cx: Cx;
}

export type MakeStylesHook<ClassNames extends string = string, Parameters = void> =
    (Parameters extends {} ?
        (parameters: Parameters) => MakeStylesResult<ClassNames> :
        () => MakeStylesResult<ClassNames>
    );